import { createRouter, createWebHistory } from 'vue-router';
import { routeGuard, startDatePT, endDatePT } from './routeGuards'; // Import your route guard and dates

import Home from './Pages/Home/HomePage.vue';
import Upload from './Pages/Upload/Upload.vue';
import BookLibrary from './Pages/BookLibrary/BookLibrary.vue';
import ParticipatingProducts from './Pages/ParticipatingProducts/ParticipatingProducts.vue';
import Support from './Pages/Support/Support.vue';
import PreProgram from './Pages/Home/Homepagecomponents/PreProgram.vue';
import PostProgram from './Pages/Home/Homepagecomponents/PostProgram.vue';

const routes = [
  {
    path: '/',
    redirect: '/Home',
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home, // our.domain/Home
    meta: { title: 'Home' },
    props: { startDate: startDatePT, endDate: endDatePT }, // Pass props
  },
  {
    path: '/Upload',
    name: 'Upload',
    component: Upload, // our.domain/Upload
    meta: { title: 'Upload' },
  },
  {
    path: '/BookLibrary',
    name: 'BookLibrary',
    component: BookLibrary, // our.domain/Upload
    meta: { title: 'Book Library' },
  },
  {
    path: '/ParticipatingProducts',
    name: 'ParticipatingProducts',
    component: ParticipatingProducts, // our.domain/Upload
    meta: { title: 'Participating Products' },
  },
  {
    path: '/Support',
    name: 'Support',
    component: Support, // our.domain/Faq
    meta: { title: 'Support' },
  },
  {
    path: '/PreProgram',
    name: 'PreProgram',
    component: PreProgram,
    meta: { title: 'Pre Program' },
    props: { startDate: startDatePT }, // Pass props
  },
  {
    path: '/PostProgram',
    name: 'PostProgram',
    component: PostProgram,
    meta: { title: 'Post Program' },
    props: { endDate: endDatePT }, // Pass props
  },
  { path: '/:notFound(.*)', redirect: '/Home' },
];

const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(routeGuard);

export default router;
