<template>
  <div class="container text-center pb-5 mt-5">
    <h1 class="text-uppercase mt-5 mb-3 d-none">Get Started</h1>
    <p class="d-none">Click Get Started below to submit your proof of purchase record to receive your book!</p>
    <div class="mb-5">
      <section class="wrapper">
        <component :is="WidgetComponent" campaign-id="096d9026-09a4-4084-a46b-f39086974b75"></component>
      </section>
    </div>
    
  </div>
</template>

<script>
import CampaignWidgets from '../Home/Homepagecomponents/CampaignWidgets.vue';

export default {
  components: { CampaignWidgets },
  props: {

  },
  data() {
    return {
      showComponent: false,
      WidgetComponent: CampaignWidgets,
    };
  },
};
</script>
