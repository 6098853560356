<template>
  <section id="header-image">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/banner-en.jpg"
            class="d-none d-md-block img-fluid w-100"
            alt="banner1"
          />
          <img
            src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/banner-en.jpg"
            class="d-md-none img-fluid w-100"
            alt="banner1"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style scoped></style>
