<template>
  <!-- Pre-program content -->
  <main role="main" class="container">
    <div class="text-center mt-5 mb-5 pb-2">
      <h1 class="text-uppercase text-center mb-4">
        Coming Soon!
      </h1>
      <p class="mb-4">Have you already purchased 2 specially-marked boxes of Kellogg’s<sup>®</sup> cereal?  <br>Make sure to keep your proof of purchase record and come back on {{ startDate }} to upload your proof of purchase record to receive your book.  
      </p>
      <p>
        For more information, see complete offer <a href="https://snipp.us/!ctbeK" target="_blank">Terms &amp; Conditions</a>.
      </p>
    </div>
    <h2 class="text-center mb-4">How To Participate</h2>

<div class="row text-center mb-5 justify-content-center htsect pb-5">
  <div class="col-md-3">
    <div class="">
      <img
        src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/shop.png"
        alt="Shop"
        class="img-fluid mb-3"
      />
      <h4>SHOP</h4>
      <p>
        Purchase 2 specially marked boxes of Kellogg’s<sup>®</sup> cereals by January&nbsp;31,&nbsp;2025 in one transaction. 
      </p>
    </div>
  </div>

  <div class="col-md-3">
    <div class="">
      <img
        src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/snap.png"
        alt="SNAP"
        class="img-fluid mb-3"
      />
      <h4>SNAP</h4>
      <p>
        Snap a clear photo of your entire proof of purchase.
      </p>
    </div>
  </div>

  <div class="col-md-3">
    <div class="">
      <img
        src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/upload.png"
        alt="UPLOAD AND REGISTER"
        class="img-fluid mb-3"
      />
      <h4>UPLOAD AND REGISTER</h4>
      <p>
        Provide your details and upload your proof of purchase.
      </p>
    </div>
  </div>

  <div class="col-md-3">
    <div class="">
      <img
        src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/book.png"
        alt="CHOOSE"
        class="img-fluid mb-3"
      />
      <h4>CHOOSE</h4>
      <p>
        After your proof of purchase is verified, you will receive an email confirmation and a request to select your book.
      </p>
    </div>
  </div>
</div>
  </main>
</template>
<script>
export default {
  props: {
    startDate: {
      type: Date,
      required: true,
    },
  },
};
</script>
<style scoped>
.navbar-nav {
  display: none;
}
</style>
