// routeGuards.js
import { isBefore, isAfter } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

// Manually entered dates (in Pacific Time)
const startDate = new Date('2024-09-23T00:00:00'); // Start date - 23/09/2024 Assuming 00:00 Pacific Time 
const endDate = new Date('2025-02-28T23:59:59'); // End date - 28/02/2025 Assuming 23:59 Pacific Time 

const timeZone = 'America/New_York';

// Function to create a date with the given time and keep the date part the same
const setTimeForDate = (date, hours, minutes, seconds) => {
  const newDate = new Date(date);
  newDate.setHours(hours, minutes, seconds);
  return newDate;
};

// Convert only the time component to Pacific Time, keep the date the same
const startDateWithTime = setTimeForDate(new Date(startDate), 0, 0, 0); // Midnight Pacific Time
const endDateWithTime = setTimeForDate(new Date(endDate), 23, 59, 59); // End of day Pacific Time

const formatDate = (date) => formatInTimeZone(date, timeZone, 'MM/dd/yyyy'); // Format date with time zone and time

const now = new Date();
const currentDate = formatDate(now);
const startDatePT = formatDate(startDateWithTime);
const endDatePT = formatDate(endDateWithTime);
console.log(startDatePT);
const routeGuard = (to, from, next) => {
  const DEFAULT_TITLE = "Kellogg's Feeding Reading Promotion";
  document.title = to.meta.title
    ? to.meta.title + " | Kellogg's Feeding Reading Promotion"
    : DEFAULT_TITLE;

  if (isBefore(currentDate, startDatePT)) {
    if (to.name !== 'PreProgram') {
      return next({ name: 'PreProgram' });
    } else {
      return next();
    }
  } else if (isAfter(currentDate, endDatePT)) {
    if (to.name !== 'PostProgram') {
      return next({ name: 'PostProgram' });
    } else {
      return next();
    }
  } else {
    // Check if user is currently on PreProgram page and redirect to Home
    if (to.name === 'PreProgram') {
      // Redirecting from PreProgram to Home
      return next({ name: 'Home' });
    }
    // Allowing access to other routes
    return next();
  }
};

export { routeGuard, startDatePT, endDatePT };
