<template>
  <!-- Pre-program content -->
  <main role="main" class="container">
    <div class="text-center mt-5 mb-5 pb-5">
      <h1 class="text-uppercase text-center mb-4">
        The Promotion is now closed
      </h1>
      <p class="mb-4">We’re sorry, but this promotion has ended. <br>Receipts must have been submitted by {{ endDate }} to qualify for this promotion. 
      </p>
      <p>
        Please visit <a href="https://wkkellogg.ca/" target="_blank">WKKellogg.ca</a> to learn about our current products and future promotions.
      </p>
    </div>
  </main>
</template>
<script>
export default {
  props: {
    endDate: {
      type: Date,
      required: true,
    },
  },
};
</script>
