<template>
  <footer>
    <div class="footer-wrap">
      <div class="container mb-3">
        <div class="abbv-blk">
          <small class="d-block"><sup>††</sup>Offer good while quantities last. Submit proof of purchase record online to receive a
            book. Both boxes must be purchased within one transaction by 11:59:59&nbsp;ET&nbsp;p.m. on January&nbsp;31,&nbsp;2025. Proof of
            purchase record must clearly show retailer name, date of purchase, qualifying products purchased, total
            transaction dollar amount and must be submitted by 11:59:59&nbsp;ET&nbsp;p.m. on February&nbsp;28,&nbsp;2025. Each proof of
            purchase record can only be submitted for redemption once and is subject to verification. Limit of five&nbsp;(5)
            books per household. Total of 30,000 books available. Book titles are subject to availability. Books will
            arrive by mail in approximately 3-5 weeks. WK Kellogg Canada is not responsible for any lost, stolen,
            misdirected or damaged shipments. Available to Canadian shipping addresses; must be age of majority. The
            covers and books included in this program are the intellectual property of or licensed for authorized use by
            Penguin Random House Canada.</small>
        </div>
      </div>
      <div class="footer-bar">
        <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/footer-bar.png"
          alt="bar" class="img-fluid" width="100%">
      </div>
      <div class="container-fluid">
        <div class="navbar">
          <div class="col-sm-12 p-0 text-center">
            <ul class="list-inline mb-0 small" id="footer-nav">
              <li class="list-inline-item">
                <a href="https://snipp.us/!ctbeK" target="_blank">Terms &amp; Conditions</a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.wkkellogg.ca/en/privacy-notice" target="_blank">Privacy Notice</a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.wkkellogg.ca/en/usage-terms" target="_blank">Terms of Use</a>
              </li>
              <li>
                <a nohref="nohref" class="ot-sdk-show-settings" style="cursor: pointer">Cookie Preferences</a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.wkkellogg.ca/en/accessibility" target="_blank">Accessibility</a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.wkkellogg.ca/en/" target="_blank">WKKellogg.ca</a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.wkkellogg.ca/en/contact-us" target="_blank">Contact Us</a>
              </li>
            </ul>
            <p class="mt-3">
              <small>© {{ new Date().getFullYear() }} WK Kellogg Canada Corp.</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  color: #fff;
  margin-top: auto;
}

.footer-wrap {
  background-color: #513629;
  padding: 3rem 0;
  position: relative;
}

.footer-wrap:before {
  content: "";
  background-image: url(https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/footer-top.png);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.footer-bar {
  background-image: url('');
}

#footer-nav li+li:before {
  content: ' | ';
  color: #fff;
  margin-right: 7px;
}

.footer-social-nav .social-icon {
  color: #fff;
  font-size: 2rem;
  border-radius: 5px;
}

.footer-social-nav {
  float: right;
}

.footer-social-nav .social-icon:hover {
  color: #176198;
}

.list-inline>li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

a {
  font: inherit;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  margin: 5px 0;
  display: inline-block;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
.abbv-blk {
    color: #a8a8a8;
}
@media screen and (max-width: 575px) {
  .navbar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #footer-nav li+li:before {
    content: '';
    margin-right: 0px;
  }

  footer {
    text-align: center;
  }

  footer li.list-inline-item {
    display: block;
  }

  footer li.list-inline-item {
    display: block;
  }

  .footer-social-nav {
    float: none;
  }
}
</style>
