<template>
  <div class="container mt-5 mb-5 pb-5" id="cardContainer">
    <h1 class="text-uppercase text-center mb-4">Book Library</h1>
    <p class="text-center mb-4">If a book is out of stock we will try to get it back in stock shortly. <br>Please come
      back later or select another amazing book.</p>
    <p class="text-center mb-2">Sort by:</p>
    <!-- Sorting Dropdown -->
    <div class="row justify-content-center text-center">
      <div class="col-md-3 mb-3">
        <select v-model="selectedLanguage" class="form-select">
          <option value="">Language</option>
          <option v-for="(language, index) in availableLanguages" :key="index" :value="language">
            {{ language }}
          </option>
        </select>
      </div>
      <div class="col-md-3 mb-3">
        <select v-model="selectedAgeGroup" class="form-select">
          <option value="">Age Range</option>
          <option v-for="(ageGroup, index) in availableAgeGroups" :key="index" :value="ageGroup">
            {{ ageGroup }}
          </option>
        </select>
      </div>
      <div class="col-md-3 mb-3">
        <select v-model="selectedFormat" class="form-select">
          <option value="">Format</option>
          <option v-for="(Format, index) in availableFormats" :key="index" :value="Format">
            {{ Format }}
          </option>
        </select>
      </div>
    </div>

    <!-- Clear Filters Button -->
    <div class="row justify-content-center text-center mb-5">
      <div class="col-md-3">
        <button @click="clearFilters" class="btn clear-filter w-100">Clear Filters</button>
      </div>
    </div>

    <!-- Pagination Controls -->
    <div class="row justify-content-center text-center mt-5 mb-5">
      <div class="col-md-6">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item d-none" :class="{ disabled: currentPage === 1 }">
              <button class="page-link" @click="changePage(currentPage - 1)"
                :disabled="currentPage === 1">Previous</button>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
              <button class="page-link" @click="changePage(page)">{{ page }}</button>
            </li>
            <li class="page-item d-none" :class="{ disabled: currentPage === totalPages }">
              <button class="page-link" @click="changePage(currentPage + 1)"
                :disabled="currentPage === totalPages">Next</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- Card Grid -->
    <div class="container-fluid">
      <div class="row justify-content-center text-center gx-md-5">
        <div class="col-md-4 mb-5 px-md-5 col-6" v-for="(card, index) in paginatedCards" :key="index">
          <div class="card">
            <div class="book-img">
              <img :src="card.image" class="img-fluid" :alt="card.Title">
            </div>
            <div class="card-body">
              <h4 class="card-title mb-0" v-html="card.Title"></h4>
              <p><small>{{ card.Author }}</small></p>
              <button type="button" class="btn btn-link" @click="openModal(card)">
                more info</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination Controls -->
    <div class="row justify-content-center text-center mt-5">
      <div class="col-md-6">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item d-none" :class="{ disabled: currentPage === 1 }">
              <button class="page-link" @click="changePage(currentPage - 1)"
                :disabled="currentPage === 1">Previous</button>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
              <button class="page-link" @click="changePage(page)">{{ page }}</button>
            </li>
            <li class="page-item d-none" :class="{ disabled: currentPage === totalPages }">
              <button class="page-link" @click="changePage(currentPage + 1)"
                :disabled="currentPage === totalPages">Next</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- Modal -->
    <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div class="modal fade show" tabindex="-1" style="display: block;" v-if="showModal">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="btn-close" @click="closeModal"></button>
              <div class="row gx-5 justify-content-between">
                <div class="col-md-5">
                  <div class="mb-3">
                    <img :src="selectedCard.image" class="img-fluid" :alt="selectedCard.Title">
                  </div>
                  <p class="mb-5"><button type="button" class="btn btn-link" @click="closeModal">Back</button></p>
                </div>
                <div class="col-md-7">
                  <h4 class="card-title mb-0" v-html="selectedCard.Title"></h4>
                  <p class="mb-0"><small class="strong"><em>{{ selectedCard.Author }}</em></small></p>
                  <p class="mb-0 font-weight-normal"><small>{{ selectedCard.Format }}</small></p>
                  <p v-if="selectedCard.Language === 'English'" class="mb-0 font-weight-normal"><small>Age : {{
          selectedCard.ageGroup }}</small></p>
                  <p v-else class="mb-0 font-weight-normal"><small>Âge : {{ selectedCard.ageGroup }}</small></p>
                  <div class="strong mb-2 mt-5">
                    <p v-if="selectedCard.Language === 'English'" class="mb-0"><strong>Overview:</strong></p>
                    <p v-else class="mb-0"><strong>Aperçu:</strong></p>
                  </div>
                  <div v-html="selectedCard.Description" class="small"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import cardsData from '../BookLibrary/Library.json'; // Adjust the path as needed

export default {
  data() {
    return {
      cards: [],
      showModal: false,
      selectedCard: null,
      selectedLanguage: '', // Default language filter (empty means no filter)
      selectedAgeGroup: '', // Default age group filter
      selectedFormat: '', // Default book type filter
      currentPage: 1,
      itemsPerPage: 24, // Number of cards per page
    };
  },
  computed: {
    availableLanguages() {
      return [...new Set(this.cards.map(card => card.Language))];
    },
    availableAgeGroups() {
      return [...new Set(this.cards.map(card => card.ageGroup))].sort((a, b) => {
        const getNumber = (str) => parseInt(str.match(/\d+/)[0]);
        return getNumber(a) - getNumber(b);
      });
    },
    availableFormats() {
      const formatMapping = {
        Hardcover: ["Hardcover", "Couverture rigide"],
        Paperback: ["Paperback", "Couverture souple"],
        "Board Book": ["Board Book", "Livre cartonné"]
      };

      return Object.keys(formatMapping);
    },
    filteredCards() {
      const formatMapping = {
        Hardcover: ["Hardcover", "Couverture rigide"],
        Paperback: ["Paperback", "Couverture souple"],
        "Board Book": ["Board Book", "Livre cartonné"]
      };

      return this.cards.filter(card => {
        const selectedFormats = formatMapping[this.selectedFormat] || [];
        return (!this.selectedLanguage || card.Language === this.selectedLanguage) &&
          (!this.selectedAgeGroup || card.ageGroup === this.selectedAgeGroup) &&
          (!this.selectedFormat || selectedFormats.includes(card.Format));
      });
    },
    paginatedCards() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredCards.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredCards.length / this.itemsPerPage);
    },
  },
  watch: {
    filteredCards(newFilteredCards) {
      if (this.currentPage > 1 && newFilteredCards.length <= (this.currentPage - 1) * this.itemsPerPage) {
        this.currentPage = 1;
      }
    }
  },
  methods: {
    openModal(card) {
      this.selectedCard = card;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedCard = null;
    },
    clearFilters() {
      this.selectedLanguage = '';
      this.selectedAgeGroup = '';
      this.selectedFormat = '';
      this.currentPage = 1; // Reset to first page when filters are cleared
      this.scrollToDiv(); // Scroll to specific div after page change
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.scrollToDiv(); // Scroll to specific div after page change
      }
    },
    scrollToDiv() {
      const element = document.getElementById('cardContainer');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    beforeEnter(el) {
      // Initialize before entering
      el.style.opacity = 0;
    },
    enter(el, done) {
      // Add transition class and callback
      el.offsetHeight; // Trigger reflow
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 1;
      done();
    },
    leave(el, done) {
      // Add transition class and callback
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 0;
      done();
    },
  },
  created() {
    this.cards = cardsData; // Load the data from the JSON file
  }
};
</script>


<style scoped>
.font-weight-normal {
  font-weight: normal;
}

select.form-select,
.clear-filter {
  text-align: center;
  font-weight: bold;
  border: 3px solid #280a11 !important;
  border-radius: 5px;
  background-color: transparent;
}

.card {
  background: transparent;
  border: 0;
}

.card small {
  font-weight: normal;
}

.modal-body {
  padding: 2rem 2rem;
  background: #fff200;
}

.modal-body img {
  max-height: 70vh;
}

button.btn-close {
  position: absolute;
  right: 15px;
  top: 10px;
}

.modal.fade.show {
  background: rgba(0, 0, 0, 0.7);
}

/* Custom transition styles (if needed) */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity .5s linear;
}

.book-img {
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.book-img img {
  max-height: 330px;
  width: auto;
}

.card-body {
  display: flex;
  flex-direction: column;
  min-height: 185px;
}

.card-body button.btn.btn-link {
  margin: 0 auto 0;
}

.page-link.active,
.active>.page-link {
  background: #ca1246;
  border-color: #ca1246;
}

.pagination {
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .modal-dialog {
    max-width: 85%;
  }

  .card-body button.btn.btn-link {
    min-width: auto;
    font-size: 1rem;
    padding: 0.7rem;
  }

  .card-body {
    min-height: auto;
    padding: 1rem 0;
  }

  .book-img {
    margin: auto;
    min-height: 185px;
  }
}

@media screen and (max-width: 767px) {
  .book-img {
    width: 85%;
    margin: auto;
    min-height: 185px;
  }

  .book-img img,
  .modal-body img {
    max-height: none;
  }

  .card-body {
    min-height: auto;
    padding: 1rem 0;
  }

  button.btn-close {
    right: 10px;
    top: 5px;
  }

  .btn-link {
    padding: 0.7rem 5px;
    min-width: 135px;
    font-size: 1.1rem;
  }
}
</style>
