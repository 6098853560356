<template>
  <!-- import styles if needed -->
  <PageLoader class="mb-5 mt-5" v-if="isLoading"></PageLoader>
  <div v-if="is_loaded" v-show="!isLoading">
    <!-- import styles if needed -->

    <div v-if="is_loaded">
      <div id="current-user-wrapper">
        <snipp-current-user :campaign-id="campaignId" :user-status="user_status" language="en"
          @show-registration="showRegistration" @snipp-logout="handleSnippLogout" @show-confirmation="showConfirmation"
          custom-css="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/kellogs_overrides.css"
          @current-user="handleCurrentUser"></snipp-current-user>
      </div>
      <div id="registration-wrapper" v-if="registration_enabled">
        <snipp-registration app-source="CampaignWidget" refresh-reload="true" language="en" :campaign-id="campaignId"
          :show-confirmation="show_confirmation"
          custom-css="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/kellogs_overrides.css"
          :is-logged-out="is_logged_out" @is-registered="handleIsRegistered"></snipp-registration>
      </div>
      <div id="thanks-text" v-if="upload_enabled === 'upload_again'">
        
        <h1 class="text-uppercase mb-3">Thank you!</h1>
        <p>
          We have received your submission. <br>Please allow up to 72 hours for processing. 
        </p>
        <p>Once your proof of purchase is validated you will <br class="d-none d-md-inline-block">receive an email with instructions on how to select your book.
        <small class="d-block">View <a href="https://snipp.us/!ctbeK" target="_blank">Terms and Conditions</a>.</small>
      </p>
        <p>Do you want to enter the promotion again?</p>
      </div>
      <div id="upload-wrapper" v-if="upload_enabled">
        <div id="upload-original" v-if="upload_enabled !== 'upload_again'">
        <p>Click Upload button below to submit your proof of purchase record to receive your book!</p>
          <snipp-upload :campaign-id="campaignId" include-upload-button="true" language="en"
            @snipp-upload-result="handleSnippUploadResult"
            custom-css="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/kellogs_overrides.css"></snipp-upload>
        <div class="mt-5 mb-5 col-md-8 mx-auto">
      <p>Please note: <br>ONLY JPEG, PNG & PDF file formats are accepted. <br>Maximum of 6MB per photo/proof of purchase
        record.</p>
      <p>Tip: <br>If the proof of purchase record is too long to fit in one image, please fold it to show: qualifying
        products purchased, store name and date of purchase.</p>
      <p>If you are having issues uploading the proof of purchase record, you can mail in your submission (please refer
        to last question in the FAQ’s),</p>
      <p><router-link to="/ParticipatingProducts">Click here to view all participating products.</router-link></p>
      <p>What do I do if I am having trouble participating in the promotion? <br><router-link to="/Support">Visit our FAQ page</router-link>.</p>
    </div>
        </div>
        <div id="upload-again" v-if="upload_enabled === 'upload_again'">
          <snipp-upload :campaign-id="campaignId" include-upload-again-button="true" language="en"
            @snipp-upload-result="handleSnippUploadResult"
            custom-css="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/kellogs_overrides.css"></snipp-upload>
            <p class="mt-3">Visit <a href="https://www.wkkellogg.ca/en-ca/home.html" target="_blank">WKKellogg.ca</a> for recipes, news and product information. </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageLoader from '../../../components/UI/PageLoader.vue';
export default {
  components: { PageLoader },
  props: ['campaignId'],
  emits: [],
  data() {
    return {
      ts: Date.now(),
      isLoading: true,
      is_loaded: false,
      is_logged_out: undefined,
      upload_enabled: undefined,
      registration_enabled: false,
      show_confirmation: undefined,
      user_status: undefined,
    };
  },
  methods: {
    showRegistration(confirmation) {
      if (this.upload_enabled) this.upload_enabled = undefined;
      if (confirmation) {
        this.show_confirmation = true;
      } else {
        this.show_confirmation = undefined;
      }
      this.registration_enabled = true;
    },
    showConfirmation() {
      this.showRegistration(true);
    },
    handleSnippLogout() {
      this.upload_enabled = undefined;
      this.is_logged_out = true;
      this.showRegistration();
    },
    handleIsRegistered() {
      this.user_status = Date.now();
      this.upload_enabled = 'upload';
      this.registration_enabled = false;
    },
    handleSnippUploadResult() {
      this.upload_enabled = 'upload_again';
    },
    handleCurrentUser() {
      this.registration_enabled = false;
      this.upload_enabled = 'upload';
    },
  },
  mounted() {
    this.is_loaded = true;
    setTimeout(() => (this.isLoading = false), 1500);
  },
};
</script>

<style scoped>
.wrapper {
  margin-top: 57px;
}
</style>
