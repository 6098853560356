<template>
  <div>
    <div>
      <!-- Home page content -->
      <main role="main" class="container">
        <div class="text-center mt-5 mb-5">
          <h1 class="text-center mb-2">
            Buy 2 specially marked boxes of <br class="d-none d-md-inline-block">Kellogg’s<sup>®</sup> cereals and get a FREE BOOK<sup>††</sup>
          </h1>
          <p class="page-subheader">
            See <a href="https://snipp.us/!ctbeK" target="_blank">Terms & Conditions</a> for full details. 
          </p>
        </div>
        <h2 class="text-center mb-4">How To Participate</h2>

        <div class="row text-center mb-5 justify-content-center htsect">
          <div class="col-md-3">
            <div class="">
              <img
                src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/shop.png"
                alt="Shop"
                class="img-fluid mb-3"
              />
              <h3>SHOP</h3>
              <p>
                Purchase 2 specially marked boxes of Kellogg’s<sup>®</sup> cereals by January&nbsp;31,&nbsp;2025 in one transaction. 
              </p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="">
              <img
                src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/snap.png"
                alt="SNAP"
                class="img-fluid mb-3"
              />
              <h3>SNAP</h3>
              <p>
                Snap a clear photo of your entire proof of purchase.
              </p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="">
              <img
                src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/upload.png"
                alt="UPLOAD AND REGISTER"
                class="img-fluid mb-3"
              />
              <h3>UPLOAD AND REGISTER</h3>
              <p>
                Provide your details and upload your proof of purchase.
              </p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="">
              <img
                src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/book.png"
                alt="CHOOSE"
                class="img-fluid mb-3"
              />
              <h3>CHOOSE</h3>
              <p>
                After your proof of purchase is verified, you will receive an email confirmation and a request to select your book.
              </p>
            </div>
          </div>
        </div>
        <div class="text-center readytosubmit mt-5 mb-5 pb-5">
          <div class="row d-flex justify-content-between align-items-center">
            <div class="col-12">
              <h2 class="">Ready to Submit?</h2>
              <p>Click Get Started below to submit your proof of purchase record to receive your book!</p>
              <p><router-link to="/Upload" class="btn btn-link">Get Started</router-link></p>
              <p class="small"><router-link to="/ParticipatingProducts">Click here to view all participating products.</router-link></p>
              <p class="small">What do I do if I am having trouble participating in the promotion? <br><router-link to="/Support">Visit our FAQ page</router-link>.</p>
              <h2 class="mt-4">What books are available?</h2>
              <p><router-link to="/BookLibrary" class="btn btn-link">View Library</router-link></p>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

export default {
  components: {  },
  props: {
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      showComponent: false,
    };
  },
};
</script>

<style scoped>
.htsect h3{font-size: 1.4rem;}
@media screen and (max-width: 575px) {
}
</style>
